
  import {fixedRouteSlugs} from '@/routes.config';
  import ScreenSize from '@/mixins/ScreenSize';

  export default {
    name: 'DefaultFooter',
    components: {
      SocialIcons: () => import('@/components/SocialIcons'),
    },
    mixins: [ScreenSize],
    props: {
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      footerNavigation: {
        id: 1,
        created_at: '2023-10-05T19:59:01.117Z',
        updated_at: '2023-10-13T13:21:05.970Z',
        items: [
          {
            id: 35,
            internal_title: 'Leistungen',
            main: {
              id: 206,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Leistungen',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 209,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Job inserieren',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 93,
                  InternalTitle: 'Job inserieren',
                  slug: 'job-inserieren',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeige schalten',
              },
              {
                id: 210,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Multiposting',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 99,
                  InternalTitle: 'Multiposting',
                  slug: 'multiposting',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Mehr Reichweite für Ihre Jobs',
              },
              {
                id: 223,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Sonderwerberformen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 96,
                  InternalTitle: 'Sonderwerbeformen',
                  slug: 'sonderwerbeformen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Mehr Aufmerksamkeit für Ihren Job',
              },
              {
                id: 224,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Bewerberdatenbank',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 97,
                  InternalTitle: 'Bewerberdatenbank',
                  slug: 'bewerberdatenbank',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Bewerberdaten freischalten',
              },
            ],
          },
          {
            id: 39,
            internal_title: 'Personal finden',
            main: {
              id: 233,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Personal finden',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 234,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Promotionpersonal finden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 113,
                  InternalTitle: 'Landingpage Promotionpersonal finden',
                  slug: 'promotionpersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 235,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Messepersonal finden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 110,
                  InternalTitle: 'Landingpage Messepersonal finden',
                  slug: 'messepersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 236,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Eventpersonal finden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 112,
                  InternalTitle: 'Landingpage Eventpersonal finden',
                  slug: 'eventpersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 237,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Gastronomiepersonal finden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 124,
                  InternalTitle: 'Landingpage Gastronomiepersonal finden NEU',
                  slug: 'gastronomiepersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2025-01-30T20:56:16.676Z',
                  updated_at: '2025-02-07T12:22:22.642Z',
                  published_at: '2025-02-06T10:35:02.093Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 36,
            internal_title: 'Service',
            main: {
              id: 207,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Service',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 212,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Kontakt',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 103,
                  InternalTitle: 'Kontaktdaten',
                  slug: 'kontaktdaten',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Hilfe bei Stellenanzeigen & Recruiting-Kampagnen',
              },
              {
                id: 213,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Hilfe & FAQ',
                icon: null,
                class: null,
                href: '',
                target: '',
                route: {
                  name: 'organization-articles-category',
                  params: {
                    category: 'hilfe-jobanbieter',
                  },
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: 'Hilfe beim Recruiting',
              },
              {
                id: 214,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'News & Infos',
                icon: null,
                class: null,
                href: '',
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {
                    category: 'news-promotionbasis',
                  },
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: 'Aktuelles bei Promotionbasis',
              },
              {
                id: 222,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Login',
                icon: null,
                class: null,
                href: 'https://jobanbieter.promotionbasis.de/',
                target: '_blank',
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: 'Verwaltung von Stellenanzeigen & Bewerber',
              },
            ],
          },
          {
            id: 38,
            internal_title: 'Sonstiges',
            main: {
              id: 220,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Sonstiges',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 221,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Neukunden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 100,
                  InternalTitle: 'Neukundenseite',
                  slug: 'stellenanzeige-schalten-kostenfrei',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Infos für Neukunden',
              },
              {
                id: 225,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Tipps für Job-Inserate',
                icon: null,
                class: null,
                href: '',
                target: null,
                route: {
                  name: 'organization-articles-category-article',
                  params: {
                    article:
                      'suchmaschinen-optimierung-stellenanzeigen-promotion-messe-und-event-jobs-1',
                    category: 'stellenanzeigen-tipps',
                  },
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: 'Suchmaschinenoptimierte Stellenanzeigen schreiben',
              },
              {
                id: 226,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Wiki',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 104,
                  InternalTitle: 'Wiki-Übersicht Jobanbieter',
                  slug: 'organization-wikis',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Marketing-Begriffe erklärt',
              },
            ],
          },
          {
            id: 37,
            internal_title: 'Rechtliches',
            main: {
              id: 208,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Rechtliches',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 215,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Impressum',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 15,
                  InternalTitle: 'Impressum',
                  slug: 'impressum',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: '113',
                  scope: 'promotionbasis',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Impressum',
              },
              {
                id: 216,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Datenschutzhinweise',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 102,
                  InternalTitle: 'Datenschutzhinweise',
                  slug: 'datenschutzhinweise',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Datenschutzhinweise',
              },
              {
                id: 217,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'AGB & Nutzungsbedingungen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 101,
                  InternalTitle:
                    'Allgemeine Geschäfts- und Nutzungsbedingungen (AGB)',
                  slug: 'agb-nutzungsbedingungen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Allgemeine Geschäftsbedingungen',
              },
            ],
          },
        ],
      },
      menuItems: [],
      iconColor: 'white--text',
      panel: [0, 1, 2, 3, 4],
    }),
    mounted() {
      if (this.isMobile) {
        this.panel = [];
      }
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return 'organization-footer';
    },
    async created() {
      /* Json für Navigation zunächst statisch und nicht mehr dynamisch aus Strapi!
      // Query for app bar navigation single type
      this.footerNavigation = await this.$cachedStrapi
        .find('organization-footer-navigation')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
       */

      if (
        this.footerNavigation &&
        this.footerNavigation.items &&
        this.footerNavigation.items.length > 0
      ) {
        this.footerNavigation.items.forEach((menuItem) => {
          // prepare main menu item
          const mainMenuItem = this.prepareMenuItem(menuItem.main);
          mainMenuItem.items = [];

          // prepare menu sub items
          if (menuItem.items && menuItem.items.length > 0) {
            menuItem.items.forEach((subItem) => {
              const subMenuItem = this.prepareMenuItem(subItem);
              mainMenuItem.items.push(subMenuItem);
            });
          }

          this.menuItems.push(mainMenuItem);
        });
      }
    },
    methods: {
      changeCookies() {
        this.$store.dispatch('cookieConsent/showCookieConsentBanner');
      },
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page && item.page.slug) {
          if (fixedRouteSlugs.includes(item.page.slug)) {
            route = {
              name: item.page.slug,
            };
          } else if (item.page.scope === process.env.SCOPE_CANDIDATE) {
            route = {
              name: 'slug',
              params: {slug: item.page.slug},
            };
          } else {
            route = {
              name: 'organization-slug',
              params: {slug: item.page.slug},
            };
          }
        } else if (item.article) {
          route = {
            name: 'organization-articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.activity) {
          route = {
            name: 'landingpages-activities-activity',
            params: {
              activity: item.activity.slug,
            },
          };
        } else if (item.city) {
          route = {
            name: 'landingpages-cities-city',
            params: {
              city: item.city.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;

        return menuItem;
      },
    },
  };
