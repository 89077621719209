
  import {fixedRouteSlugs} from '@/routes.config';

  export default {
    name: 'DefaultAppBar',
    components: {
      LoginAndRegistrationDialog: () =>
        import('@/components/organization/LoginAndRegistrationDialog'),
      RegistrationWithJobPostingDialog: () =>
        import('@/components/organization/RegistrationWithJobPostingDialog'),
      MetaNavigation: () => import('@/components/elements/MetaNavigation'),
      GeneralContactDialog: () => import('@/components/GeneralContactDialog'),
    },
    data: () => ({
      appBarNavigation: {
        id: 1,
        created_at: '2023-09-29T10:08:07.117Z',
        updated_at: '2024-11-09T00:11:58.170Z',
        items: [
          {
            id: 31,
            internal_title: 'Job schalten',
            main: {
              id: 198,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Job schalten',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: 'Job inserieren',
            },
            items: [
              {
                id: 247,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Job inserieren',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 93,
                  InternalTitle: 'Job inserieren',
                  slug: 'job-inserieren',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T10:02:23.014Z',
                  updated_at: '2023-12-13T09:38:32.756Z',
                  published_at: '2023-09-29T10:55:37.899Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 248,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Gratis testen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 100,
                  InternalTitle: 'Neukundenseite',
                  slug: 'stellenanzeige-schalten-kostenfrei',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T08:35:51.086Z',
                  updated_at: '2024-01-10T09:37:14.580Z',
                  published_at: '2023-10-02T08:37:34.987Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 249,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Login',
                icon: 'fal fa-right-to-bracket',
                class: null,
                href: 'https://jobanbieter.promotionbasis.de',
                target: '_blank',
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                scheme: 'yellow',
              },
              {
                id: 250,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Anmelden',
                icon: 'fal fa-right-to-bracket',
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                type: 'dialog',
                dialogUrl: '/?registration=1',
                scheme: 'yellow',
              },
            ],
          },
          {
            id: 32,
            internal_title: 'Lösungen',
            main: {
              id: 199,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Lösungen',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: 'Recruiting-Lösungen',
            },
            items: [
              {
                id: 263,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Produkt-Übersicht',
                icon: 'fal fa-star',
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 122,
                  InternalTitle: 'Lösungen',
                  slug: 'loesungen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T10:02:23.014Z',
                  updated_at: '2023-12-13T09:38:32.756Z',
                  published_at: '2023-09-29T10:55:37.899Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
                scheme: 'white',
              },
              {
                id: 241,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Anzeigenoptionen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                type: 'middle',
              },
              {
                id: 242,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Job-Inserate flexibel',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 123,
                  InternalTitle: 'Job inserate',
                  slug: 'job-inserate',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T10:02:23.014Z',
                  updated_at: '2023-12-13T09:38:32.756Z',
                  published_at: '2023-09-29T10:55:37.899Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 202,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Abo-Pakete',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 94,
                  InternalTitle: 'Stellenanzeigen-Pakete',
                  slug: 'stellenanzeigen-pakete',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T12:15:36.954Z',
                  updated_at: '2023-12-12T10:41:55.847Z',
                  published_at: '2023-09-29T12:29:50.138Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeigen-Pakete',
              },
              {
                id: 203,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Kontingente',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 95,
                  InternalTitle: 'Stellenanzeigen-Kontingente',
                  slug: 'stellenanzeigen-kontingente',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T13:03:33.205Z',
                  updated_at: '2023-12-12T10:39:26.526Z',
                  published_at: '2023-09-29T13:18:46.147Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeigen-Kontingente',
              },
              {
                id: 243,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Personalsuche',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                type: 'middle',
              },
              {
                id: 205,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Bewerberdatenbank',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 97,
                  InternalTitle: 'Bewerberdatenbank',
                  slug: 'bewerberdatenbank',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T08:06:39.989Z',
                  updated_at: '2023-12-12T10:14:17.434Z',
                  published_at: '2023-10-02T08:11:38.731Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Bewerberdatenbank',
              },
              {
                id: 244,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Reichweitenprodukte',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                type: 'middle',
              },
              {
                id: 245,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Multiposting',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 99,
                  InternalTitle: 'Multiposting',
                  slug: 'multiposting',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T08:19:51.210Z',
                  updated_at: '2023-12-12T09:59:08.444Z',
                  published_at: '2023-10-02T08:22:01.845Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 204,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Sonderwerbeformen ',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 96,
                  InternalTitle: 'Sonderwerbeformen',
                  slug: 'sonderwerbeformen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T06:26:40.932Z',
                  updated_at: '2023-12-14T21:23:21.153Z',
                  published_at: '2023-10-02T06:45:22.078Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Sonderwerbeformen ',
              },
              {
                id: 246,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Angebot anfragen',
                icon: 'fal fa-right-to-bracket',
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
                type: 'dialog',
                dialogUrl: '/?topic=general_request&generalContact=1',
                scheme: 'yellow',
              },
            ],
          },
          {
            id: 40,
            internal_title: 'Personal finden',
            main: {
              id: 239,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Personal finden',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 251,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Promotionpersonal',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 113,
                  InternalTitle: 'Landingpage Promotionpersonal finden',
                  slug: 'promotionpersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2024-02-27T21:43:46.463Z',
                  updated_at: '2024-03-12T21:54:21.809Z',
                  published_at: '2024-02-29T13:12:01.262Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 252,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Messepersonal',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 110,
                  InternalTitle: 'Landingpage Messepersonal finden',
                  slug: 'messepersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2024-02-26T20:42:32.354Z',
                  updated_at: '2024-03-12T21:52:57.445Z',
                  published_at: '2024-02-29T09:18:29.795Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 253,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Eventpersonal',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 123,
                  InternalTitle: 'Landingpage Eventpersonal finden NEU',
                  slug: 'eventpersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2024-02-26T21:45:55.472Z',
                  updated_at: '2024-10-30T00:27:11.441Z',
                  published_at: '2024-02-29T13:01:24.053Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 200,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Gastronomiepersonal',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 124,
                  InternalTitle: 'Landingpage Gastronomiepersonal finden NEU',
                  slug: 'gastronomiepersonal-finden',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2025-01-30T20:56:16.676Z',
                  updated_at: '2025-02-06T10:35:02.397Z',
                  published_at: '2025-02-06T10:35:02.093Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 33,
            internal_title: 'Warum Promotionbasis?',
            main: {
              id: 200,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Warum Promotionbasis?',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: 'Warum Promotionbasis?',
            },
            items: [
              {
                id: 260,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Über uns',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 98,
                  InternalTitle: 'Warum Promotionbasis?',
                  slug: 'warum-promotionbasis',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T08:18:22.839Z',
                  updated_at: '2023-12-15T08:17:24.998Z',
                  published_at: '2023-10-02T08:18:27.271Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 261,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Vorteile',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-slug',
                  params: {slug: 'warum-promotionbasis'},
                  hash: '#pb-section-headline-with-text-and-cards-46',
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 262,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Kundenstimmen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-slug',
                  params: {slug: 'warum-promotionbasis'},
                  hash: '#pb-section-testimonial-cards-18',
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 34,
            internal_title: 'Know-How',
            main: {
              id: 201,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Know-How',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: 'Know-How',
            },
            items: [
              {
                id: 255,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Vertrags-Muster',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {category: 'muster-vorlagen-vertraege'},
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 256,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobanzeigen-Muster',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {category: 'muster-vorlagen-stellenanzeigen'},
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 257,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Tipps für Job-Inserate',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {category: 'stellenanzeigen-tipps'},
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 258,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Tipps zum Bewerbermanagement',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {category: 'bewerbermanagement'},
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 259,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Recruiting-Insights',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: {
                  name: 'organization-articles-category',
                  params: {category: 'news-promotionbasis'},
                },
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 41,
            internal_title: 'Support',
            main: {
              id: 240,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Support',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 103,
                InternalTitle: 'Kontaktdaten',
                slug: 'kontaktdaten',
                InternalDescription: null,
                Layout: null,
                Active: null,
                created_at: '2023-10-09T09:16:24.905Z',
                updated_at: '2023-12-13T09:35:45.122Z',
                published_at: '2023-10-09T09:16:50.343Z',
                views: null,
                scope: 'promotionbasis_organization',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: 'Support',
            },
            items: [],
          },
        ],
      },
      overlay: false,
      menu: false,
      drawer: false,
      iconColor: 'primaryYellow--text',
      menuItems: [],
      menuItemsOutsideDropdown: [],
      menuItemsInDropdown: [],
      overSize: false,
      menuItemNumber: 6,
      registerMenuModel: false,
      debouncedResizeHandler: null,
    }),
    async fetch() {
      /* Json für Navigation zunächst statisch und nicht mehr dynamisch aus Strapi!
    // Query for app bar navigation single type
    const appBarNavigation = await this.$cachedStrapi
      .find('organization-app-bar-navigation')
      .catch((e) => {
        if (e.statusCode !== 404) {
          throw e;
        }
      });
     */

      if (
        this.appBarNavigation.items &&
        this.appBarNavigation.items.length > 0
      ) {
        this.appBarNavigation.items.forEach((menuItem) => {
          // prepare main menu item
          const mainMenuItem = this.prepareMenuItem(menuItem.main);
          mainMenuItem.items = [];

          // prepare menu sub items
          if (menuItem.items && menuItem.items.length > 0) {
            menuItem.items.forEach((subItem) => {
              const subMenuItem = this.prepareMenuItem(subItem);
              mainMenuItem.items.push(subMenuItem);
            });
          }
          this.menuItems.push(mainMenuItem);
          this.menuItemsOutsideDropdown = this.menuItems;
          this.menuItemNumber = this.menuItemsOutsideDropdown.length;
        });
      }
    },
    computed: {
      logoUrl() {
        return require(process.env.NUXT_ENV_FALLBACK_LOGO);
      },
    },
    watch: {
      drawer: function (val) {
        if (val) {
          document.documentElement.style.overflow = 'hidden';
        } else {
          document.documentElement.style.overflow = 'auto';
        }
      },
    },
    mounted() {
      document.documentElement.style.overflow = 'auto';
      this.calculateMainMenuSize();
      import('lodash/function').then(({debounce}) => {
        // Debounce-Funktion für das Resize-Event
        this.debouncedResizeHandler = debounce(() => {
          this.calculateMainMenuSize();
        }, 200); // 200 Millisekunden Debounce-Zeit

        window.addEventListener('resize', this.debouncedResizeHandler);
      });
    },
    beforeDestroy() {
      if (this.debouncedResizeHandler)
        window.removeEventListener('resize', this.debouncedResizeHandler);
    },
    methods: {
      openMenuDialog(url) {
        this.$router.push(this.localePath({}) + url);
      },
      checkClickOption(e, item) {
        if (item.items.length > 0) {
          e.stopPropagation();
        }
      },
      calculateMainMenuSize() {
        if (window.innerWidth >= 1800) {
          this.menuItemNumber = 6;
        } else if (window.innerWidth >= 1400 && window.innerWidth < 1800) {
          this.menuItemNumber = 4;
        } else if (window.innerWidth >= 1100 && window.innerWidth < 1400) {
          this.menuItemNumber = 3;
        } else if (window.innerWidth < 1100) {
          this.menuItemNumber = 2;
        }
        this.setupMenu();
      },
      setupMenu() {
        this.menuItemsOutsideDropdown = this.menuItems.slice(
          0,
          this.menuItemNumber
        );
        this.menuItemsInDropdown = this.menuItems.slice(this.menuItemNumber);
      },
      togglePopupMenu(event) {
        this.menu = event.menu;
        this.overlay = event.overlay;
      },
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page && item.page.slug) {
          if (fixedRouteSlugs.includes(item.page.slug)) {
            route = {
              name: item.page.slug,
            };
          } else {
            route = {
              name: 'organization-slug',
              params: {slug: item.page.slug},
            };
          }
        } else if (item.article) {
          route = {
            name: 'organization-articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;
        menuItem.type = item.type;
        menuItem.dialogUrl = item.dialogUrl;
        menuItem.scheme = item.scheme;

        return menuItem;
      },
    },
  };
