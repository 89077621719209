import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.OrganizationRegistrationWithJobPostingStepStarted, () => {
    $matomo.trackEvent(
      'OrganizationRegistrationWithJobPosting',
      Events.OrganizationRegistrationWithJobPostingStepStarted,
      '',
      1
    );
  });
  EventBus.$on(
    Events.OrganizationRegistrationWithJobPostingStepFinished,
    () => {
      $matomo.trackEvent(
        'OrganizationRegistrationWithJobPosting',
        Events.OrganizationRegistrationWithJobPostingStepFinished,
        '',
        1
      );
    }
  );
  EventBus.$on(Events.OrganizationRegistrationWithJobPostingStepClosed, () => {
    $matomo.trackEvent(
      'OrganizationRegistrationWithJobPosting',
      Events.OrganizationRegistrationWithJobPostingStepClosed,
      '',
      1
    );
  });
  EventBus.$on(
    Events.OrganizationRegistrationWithJobPostingSwitchToLogin,
    () => {
      $matomo.trackEvent(
        'OrganizationRegistrationWithJobPosting',
        Events.OrganizationRegistrationWithJobPostingSwitchToLogin,
        '',
        1
      );
    }
  );
  EventBus.$on(Events.OrganizationRegistrationWithJobPostingOpened, () => {
    $matomo.trackEvent(
      'OrganizationRegistrationWithJobPosting',
      Events.OrganizationRegistrationWithJobPostingOpened,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationWithJobPostingFinished, () => {
    $matomo.trackEvent(
      'OrganizationRegistrationWithJobPosting',
      Events.OrganizationRegistrationWithJobPostingFinished,
      '',
      1
    );
  });
}
